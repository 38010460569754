<template>
  <div class="columns">
    <div class="column is-one-third">
        <card-component
          title="1. Set options to extract new dataset"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <b-field
              label="Select a source"
            >
              <b-select
                v-model="source"
                placeholder="Source dataset"
                class="mb-2"
                expanded
                icon="location-arrow"
              >
                <option value="multiple">from multiple tests</option>
                <option value="posthoc" v-if="posthocObjectName!==null">from posthoc tests</option>
              </b-select>
          </b-field>
          <validation-observer v-slot="{ handleSubmit }">
            <b-field
              label="Type"
            >
              <b-select
                v-model="type"
                placeholder="Type"
                class="mb-2"
                expanded
                icon="wrench"
              >
                <option value="dataset">extract of the original numeric dataset</option>
                <option value="results">extract of the test table</option>
              </b-select>
            </b-field>
            <div  v-if="source==='posthoc'">
              <b-field
                label="Conditions"
              >
                <b-switch
                  v-model="useAllCondition"
                  >
                  Use all conditions<br>
                </b-switch>
              </b-field>
              <validation-provider
                v-slot="{ errors }"
                :rules= {required:true}
                name="Variables"
                v-if="!useAllCondition"
              >
                <b-field
                :type="{ 'is-danger': errors[0]}"
                >
                  <b-taginput
                    v-model="conditions"
                    :data="conditionsList"
                    :allow-new="false"
                    ellipsis
                    icon="list"
                    autocomplete
                    :openOnFocus="true"
                    placeholder="Select condition(s)"
                    aria-close-label="Delete this tag">
                  </b-taginput>
                </b-field>
              </validation-provider>
            </div>
            <b-field
              label="Sign"
            >
              <b-select
                v-model="sign"
                class="mb-2"
                expanded
                icon="asterisk"
              >
                <option v-for="(item, key, index)  in signAvail"
                  :key="index" :value="key">{{item}}</option>
              </b-select>
            </b-field>
            <div v-if="type==='results'">
              <b-field
                  label="Variables"
                >
                  <b-switch
                    v-model="useAllVariable"
                    >
                    Use all variables<br>
                  </b-switch>
              </b-field>
              <validation-provider
                v-slot="{ errors }"
                :rules= {required:true}
                name="Variables"
                v-if="!useAllVariable"
              >
                <b-field
                :type="{ 'is-danger': errors[0]}"
                label="">
                  <b-taginput
                    v-if="!useAllVariable"
                    v-model="variables"
                    :data="allVariables"
                    :allow-new="false"
                    ellipsis
                    icon="list"
                    autocomplete
                    :openOnFocus="true"
                    placeholder="Select variable(s)"
                    aria-close-label="Delete this tag">
                  </b-taginput>
                </b-field>
              </validation-provider>
            </div>
            <b-field label="Threshold" v-if="type==='results'">
              <b-slider
                v-model.lazy="threshold"
                :min=0
                :max=1
                :step=0.05
                :tooltip=true
                :indicator=true
                type="is-grey"
                @change="computeDatasetName()"
              >
                <b-slider-tick :value="0">0</b-slider-tick>
                <b-slider-tick :value="1">1</b-slider-tick>
              </b-slider>
            </b-field>

            <validation-provider
              v-slot="{ errors, valid }"
              :rules= {excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
              :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
              does_not_contains:'The dataset name should not contain special symbols (except underscore) nor start with numbers.',
              authorize_name: 'This dataset name is not allowed',
              not_too_long: 'This dataset name is too long'}"
              name="Dataset name"
            >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label="Dataset name"
              >
                <b-input
                  v-model="datasetNameExtract"
                  expanded
                  icon="tag"
                ></b-input>
              </b-field>
            </validation-provider>
            <hr />
            <div class="has-text-centered">
              <b-button rounded
                class="is-primary button-shadow"
                @click.prevent="handleSubmit(run)"
              >
                <span class="icon is-small"><i class="fa fa-cogs"></i></span>
                <span> Extract new dataset </span>
              </b-button>
            </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component
            v-if="!newDatasetName"
            title="2. How to extract a new dataset?"
          >
          </card-component>
          <card-no-result v-if="!resultsLoaded && !newDatasetName">
            <strong>How to set options?<br></strong>
            <p>This screen can be used to create a new dataset in your
            workspace:</p>
            <ul>
              <li>with Type “<strong>extract of the original dataset</strong>”,
              you can extract a subset of the tested dataset containing only
              differential variables. When relevant, only over- or under-
              expressed variables can be included. In addition, for posthoc
              tests, variables differential for only some of the contrasts can
              be extracted;</li>
              <li>with Type “<strong>extract of the test table</strong>”, you
              can extract the table of results (p-values, test statistics, ...)
              that can be further filtered by sign, variable names, contrasts,
              or p-value threshold (if the threshold is set to 1, all results
              are extracted).</li>
            </ul>
          </card-no-result>
          <error :type='"notifications"' />
          <div v-if="newDatasetName">
            <dataset-view :dataset-name="newDatasetName" />
          </div>
        </div>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RExtractDiff',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-view': () => import('@/components/DatasetView.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    multipleTestObjectName: {
      type: String,
      default: null
    },
    posthocObjectName: {
      type: String,
      default: null
    },
    conditionsList: {
      type: Array,
      default: null
    },
    allVariables: {
      type: Array,
      default: null
    },
    signs: {
      type: Object,
      default: null
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      type: 'dataset',
      conditions: [],
      sign: 'both',
      variables: [],
      threshold: 1,
      source: 'multiple',
      datasetNameExtract: '',
      newDatasetName: '',
      newDatasetUserName: '',
      useAllCondition: true,
      useAllVariable: true
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    signAvail: function () {
      let signs = { 'both': 'All results' }
      if (this.signs[this.source] === 'yes') {
        signs = { 'both': 'All results',
          '+': 'Only results over-expressed in the second condition',
          '-': 'Only results under-expressed in the second condition' }
      }
      return signs
    },
    params: function () {
      let params = {
        'func_name': 'r_extract_diff',
        'type': this.type,
        'out_graph': true
      }
      if (this.source === 'multiple') {
        params['datasetName'] = this.multipleTestObjectName
      } else {
        params['datasetName'] = this.posthocObjectName
        if (this.useAllCondition) {
          params['conditions'] = this.conditionsList
        } else {
          params['conditions'] = this.conditions
        }
      }
      params['sign'] = this.sign
      if (this.type === 'results') {
        if (this.useAllVariable) {
          params['variable'] = this.allVariables
        } else {
          params['variable'] = this.variables
        }
        params['threshold'] = this.threshold
      }
      if (this.datasetNameExtract !== '') {
        params['userName'] = this.datasetNameExtract
      }
      return params
    },
    datasetsNames: function () {
      return this.workspaceStore.datasets.map(dataset => dataset.user_name)
    }
  },
  mounted () {
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      }, (err) => {
        return {
          valid: false,
          data: { message: 'This dataset name is forbidden.' + err }
        }
      })
    })
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
    this.computeDatasetName()
  },
  watch: {
    type: function () {
      this.computeDatasetName()
    },
    source: function () {
      this.computeDatasetName()
    }
  },
  methods: {
    computeDatasetName: function () {
      this.datasetNameExtract = this.type + '_' + this.params['datasetName']
    },
    beforeRun: function () {
      this.newDatasetName = ''
      this.newDatasetUserName = ''
    },
    afterRun: function (data) {
      this.newDatasetName = data.ObjectName
      this.newDatasetUserName = data.UserName
      this.datasetNameExtract = ''
    },
    selectVarname: function (value) {
      this.variables = value
    }
  }
}
</script>
<style scoped>
ul {
  list-style: inside;
  padding-left: 10px;
}
.b-slider .b-slider-track {
    height: 0.5rem;
    background-color: #20BFF0;
}
</style>
